<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">标题:</span>
        <el-input class="width_200" clearable v-model="search.title" placeholder="请输入标题"></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">分类:</span>
        <el-select v-model="search.type" clearable>
          <el-option :value="0" label="全部"></el-option>
          <el-option v-for="item of Types" :key="item" :value="item.id" :label="item.title"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showDialog()">添加模板</el-button>
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
      </div>
      <div class="search-box" v-if="selectRows.length > 0">
        <el-button type="danger" @click="delMulti">批量删除</el-button>
      </div>
    </div>
    <el-table :data="tableData" @selectionChange="selectChange" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" width="200" label="标题" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-badge v-if="scope.row.is_hot" value="hot" class="hot_item" type="danger">
            <span>{{ scope.row.title }}</span>
          </el-badge>
          <span v-else
            ><span>{{ scope.row.title }}</span></span
          >
        </template>
      </el-table-column>
      <el-table-column prop="image" label="封面图" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <ximg :src="scope.row.image" :width="30" :height="30" :enlarge="true" alt=""></ximg>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="faces" label="脸图数据" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{ getTypesBy(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="时长" align="center" :show-overflow-tooltip="true"> </el-table-column>
      <el-table-column prop="size" label="大小" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{ parseSize(scope.row.size) }}
        </template>
      </el-table-column>
      <el-table-column prop="times" label="次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="addtime" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.status" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>

      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="showDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row.id)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
    <!-- 添加/修改 -->
    <el-dialog custom-class="video-box" :title="title" v-model="dialog" width="1100px" top="10vh">
      <div class="list-left">
        <div class="video-list-box">
          <div class="video-list">
            <div class="video-list-title">视频:</div>
            <div class="video-list-content">
              <el-upload
                class="avatar-uploader"
                style="width: 200px; height: 200px"
                accept="video/mp4"
                action="#"
                :before-upload="beforeUploadVideo"
                :show-file-list="false"
                :http-request="uploadVideo"
              >
                <video ref="video" width="200" height="200" controls :src="videoForm.url" v-if="videoForm.url"></video>
                <el-button v-else type="primary">上传</el-button>
                <template #tip>
                  <div class="el-upload__tip">仅支持MP4</div>
                </template>
              </el-upload>
            </div>
          </div>
          <div class="video-list">
            <div class="video-list-title">封面图:</div>
            <div class="video-list-content">
              <el-upload
                  class="avatar-uploader"
                  style="width: 200px; height: 200px"
                  accept="image/*"
                  action="#"
                  :show-file-list="false"
                  :http-request="upload"
              >
                <ximg :height="100" :width="100" v-if="form.imgUrl" :src="form.imgUrl" :enlarge="false"></ximg>
                <el-button v-else type="primary">上传</el-button>
                <template #tip>
                  <div class="el-upload__tip">仅支持jpg/png</div>
                </template>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="faces-list">
          <ximg :height="100" :width="100" :enlarge="false" v-for="face of faces" :key="face" :src="face.src"></ximg>
        </div>
      </div>
      <div class="list-right">
        <div class="right-list">
          <div class="right-list-title">标题:</div>
          <div class="right-list-content">
            <el-input class="width_400" v-model="form.title" placeholder="标题" size="small"></el-input>
          </div>
        </div>
        <div class="right-list">
          <div class="right-list-title">价格:</div>
          <div class="right-list-content">
            <el-input type="number" v-model="form.price" placeholder="价格" size="small"></el-input>
          </div>
        </div>
        <div class="right-list">
          <div class="right-list-title">次数:</div>
          <div class="right-list-content">
            <el-input v-model="form.times" placeholder="次数" size="small"></el-input>
          </div>
        </div>
        <div class="right-list">
          <div class="right-list-title">权重:</div>
          <div class="right-list-content">
            <el-input type="number" v-model="form.weight" placeholder="权重" size="small"></el-input>
          </div>
        </div>
        <div class="right-list">
          <div class="right-list-title">分类:</div>
          <div class="right-list-content">
            <el-select  class="width_400" v-model="form.types" multiple>
              <el-option v-for="item of Types" :key="item" :value="'' + item.id" :label="item.title"></el-option>
            </el-select>
          </div>
        </div>
        <div class="right-list">
          <div class="state-list">
            <div>状态:</div>
            <el-switch :active-value="1" :inactive-value="0" v-model="form.status"></el-switch>
          </div>
          <div class="state-list">
            <div>热门:</div>
            <el-switch :active-value="1" :inactive-value="0" v-model="form.is_hot"></el-switch>
          </div>
        </div>
        <div class="btns">
          <el-button type="primary" @click="dialog = false">取消</el-button>
          <el-button type="primary" @click="add" v-if="row === undefined">添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import page from "@/components/page"
import httpClient from "@/config/httpClient"
export default {
  name: "ai_video",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 20 },
      search: {
        title: "",
        type: 0,
      },
      tableData: [],
      Types: [], // 分类
      title: "添加",
      dialog: false,
      form: {
        title: "",
        price: 0,
        status: 0,
        times: 3000,
        is_hot: 0,
        types: [], // 分类ID
        image: "",
        src: "",
        size: 0,
        duration: 0,
        url: "",
        imgUrl: "", // 显示图片地址
      },
      faces: [],
      max_duration: 11 * 60,
      row: undefined,
      selectRows: [],
      max_size: 400 * 1024 * 1024,
      per_slice: 1024 * 1024,
      videoForm: {
        file: null,
        url: "",
      },
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getTypes()
    this.getList()
  },
  methods: {
    parseSize(size) {
      return (size / this.per_slice).toFixed(2) + "M"
    },
    getTypesBy(type) {
      var titles = []
      for (const id of type.split(",")) {
        var title = this.getType(id)
        if (title != "") {
          titles.push(title)
        }
      }
      return titles.join(",")
    },
    getType(id) {
      for (const t of this.Types) {
        if (t.id == id) {
          return t.title
        }
      }
      return ""
    },
    delMulti() {
      if (this.selectRows.length == 0) {
        return this.$message({ message: "请选择模板" })
      }
      var ids = []
      for (const row of this.selectRows) {
        ids.push(row.id)
      }
      this.del(ids.join(","))
    },
    del(ids) {
      this.$confirm("确认删除ID: " + ids + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        httpClient("ai_video_del")
          .post({ ids: ids })
          .then(res => {
            if (res.code == 0) {
              this.getList()
              return this.$message({ message: "删除成功", type: "success" })
            } else {
              return this.$message({ message: res.msg, type: "error" })
            }
          })
      })
    },
    selectChange(rows) {
      this.selectRows = rows
    },
    edit() {
      if (this.form.types) {
        this.form.type = this.form.types.join(",")
      } else {
        this.form.type = ""
      }
      this.$common.showLoading("正在编辑,并生成脸图数据,请稍等")
      httpClient("ai_video_edit")
        .post(this.form)
        .then(res => {
          this.$common.hideLoading()
          if (res.code == 0) {
            // this.getList()
            this.dialog = false
            this.$message({ message: "修改成功", type: "success" })
          } else {
            this.$message({ message: res.msg, type: "error" })
          }
        })
    },
    add() {
      if (this.form.types) {
        this.form.type = this.form.types.join(",")
      } else {
        this.form.type = ""
      }
      var faces = []
      for(var i in this.faces){
        faces.push(this.faces[i].url)
      }
      this.form.faces = JSON.stringify(faces)
      let param = this.form
      param["imgUrl"] = ""
      this.$common.showLoading("正在添加,并生成脸图数据,请稍等")
      httpClient("ai_video_add")
        .post(param)
        .then(res => {
          this.$common.hideLoading()
          if (res.code == 0) {
            this.getList()
            this.dialog = false
            this.$message({ message: "添加成功", type: "success" })
          } else {
            this.$message({ message: res.msg, type: "error" })
          }
        })
    },
    upload(param) {
      var headers = {
        "is-thumbnail": "true",
        "is-encode": "true",
        Name: "af",
      }
      this.$common.showLoading("正在上传封面图")
      // this.$message({ message: "上传中请稍后", type: "success" });
      httpClient("")
        .setHeader(headers)
        .upload(param.file)
        .then(
          res => {
            this.$common.hideLoading()
            if (res.code == 0) {
              this.form.image = res.data.filename
              this.form.imgUrl = res.data.path
              this.form.thumbnail = res.data.thumbnail
            } else {
              this.$message({ message: res.msg, type: "error" })
            }
          },
          err => {
            console.log(err)
            this.$common.hideLoading()
          }
        )
    },
    beforeUploadVideo(file) {
      this.videoForm.file = file // 文件存储起来,上传成功才给播放
      if (["video/mp4"].indexOf(file.type) == -1) {
        //  'video/ogg', 'video/flv', 'video/wmv', 'video/rmvb', 'video/avi',,'video/mov'
        this.$message({
          message: "暂不支持" + file.type + "格式!",
          type: "error",
        })
        return false
      }
      // 800M
      if (file.size > this.max_size) {
        this.$message({ message: "视频不能超过400M", type: "error" })
        return false
      }
      this.form.size = file.size
      var url = URL.createObjectURL(this.videoForm.file)
      this.videoForm.url = url
      // this.genVideo(url)
      // this.videoForm.showVideoPath = url
      this.videoForm.size = this.videoForm.file.size
      this.isShowUploadVideo = false
      setTimeout(() => {
        this.genVideoFaces(this.videoForm.file)
      }, 500)
    },

    // 绘制视频帧到canvas上
    screenshot(video,index) {
      const canvas = document.createElement("canvas")
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      // 获取2D绘图上下文
      const ctx = canvas.getContext("2d")
      // 在canvas上绘制视频帧
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataURL = canvas.toDataURL("image/png")
      // this.form.src = dataURL
      if (index == 0){
        // if (this.form.image.startsWith("data:image") || this.form.image == ""){
          this.form.imgUrl = dataURL
        // }
      }
      this.faces[index] = {
        src:dataURL,
        url:""
      }
      this.uploadFace(dataURL,index)
      return dataURL
    },
    // 捕获视频关键帧
    captureFrame(videoFile, time,index) {
      const _this = this
      // 创建一个video元素
      const video = document.createElement("video")
      // 设置视频当前播放时间为指定的时间点
      video.currentTime = time
      // 设置视频静音
      video.muted = true
      // 设置视频自动播放
      video.autoplay = true
      // 当视频可以播放时触发事件
      video.oncanplay = async () => {
        // 如果指定的时间超过了视频的总时长，则抛出错误
        if (time > video.duration) {
          throw new Error("指定时间超过视频时长")
        }
        // 调用drawImage函数绘制视频帧到canvas上，并获取绘制结果的URL
        const _url = await _this.screenshot(video,index)
        // 获取页面上的img元素
        const img = document.querySelector("img")
        // 将绘制好的图像显示在img元素上
        img.src = _url
      }
      // 将视频文件的URL赋值给video元素的src属性
      video.src = URL.createObjectURL(videoFile)
    },
    // 制作关键帧图片初始化
    genVideoFaces(videoUrl) {
      // 清空帧图片
      this.faces = []
      var per = (this.$refs["video"].duration - 1) / 4
      for (var i = 0; i <= 4; i++) {
        this.faces[i] = {
          url:"",
          src:"",
        }
        this.captureFrame(videoUrl, per * i,i)
      }
    },
    uploadVideo(param) {
      this.videoFlag = true
      if (param.file.size > this.max_size) {
        this.$message({ message: "视频不能超过800M", type: "error" })
        return
      }

      this.$common.showLoading("视频上传中:0%")
      // 检测时长是否符合要求
      setTimeout(()=>{
        console.log("视频信息:",this.$refs["video"].duration)
        this.form.duration = this.$refs["video"].duration
        if(this.form.duration > this.max_duration){
          this.$message({ message: "视频不能超过10分钟", type: "error" });
          return;
        }
        var header = {
          "name":"af/video",
          "save":true,
        }
        httpClient("").setHeader(header).uploadVideoSlice(param.file,(progress)=>{
          this.$common.showLoading(`视频上传中:${progress}%`)
        },(info)=>{
          this.$common.hideLoading()
          console.log(info)
          this.form.url = info.filename
        },(err)=>{
          this.$common.hideLoading()
          console.log("上传失败")
          console.log(err)
        })
      },500)
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    uploadFace(base64_image,index) {
      let file = this.base64ToFile(base64_image,`face${index}.png`)
      var headers = {
        // "is-thumbnail":"true",
        "is-encode": "true",
        Name: "af/video",
      }
      httpClient("")
        .setHeader(headers)
        .upload(file)
        .then(
          res => {
            if (res.code == 0) {
              this.faces[index].url = res.data.filename
              if (index == 0){
                this.form.image = res.data.filename
              }
            } else {
              this.$message({ message: res.msg, type: "error" })
            }
          },
          err => {
            console.log(err)
            this.$common.hideLoading()
          }
        )
    },
    showDialog(row) {
      this.row = row
      this.form = {
        title: "",
        price: 0,
        status: 0,
        times: 3000,
        is_hot: 0,
        types: [], // 分类ID
        image: "",
        duration: 0,
        size: 0,
        imgUrl: "", // 显示图片地址
      }
      this.faces = []
      this.videoForm.url = ""
      if (row === undefined) {
        this.title = "添加"
      } else {
        this.title = "编辑"
        this.form = row
        if (row.type != "") {
          this.form.types = row.type.split(",")
        }
        if (this.form.faces){
          this.faces = this.form.faces
        }
        this.form.imgUrl = this.form.image
        this.videoForm.url = this.form.url
      }
      console.log(this.form)
      this.dialog = true
    },
    getList() {
      var params = {
        pageid: this.pager.page - 1,
        pcount: this.pager.rows,
      }
      params = { ...params, ...this.search }
      httpClient("ai_video_list")
        .post(params)
        .then(res => {
          if (res.code == 0) {
            this.tableData = res.data.list
            if (this.pager.page == 1) {
              this.pager.total = res.data.total
            }
          } else {
            this.$message({ message: res.msg, type: "error" })
          }
        })
    },
    getTypes() {
      httpClient("getTypeSelect")
        .post({ type: 5 })
        .then(
          res => {
            if (res.code == 0) {
              this.Types = res.data
            } else {
              this.$message({ message: res.msg, type: "error" })
            }
          },
          err => {
            console.log(err)
          }
        )
    },
  },
}
</script>

<style>
/* 图片上传部分 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 200px;
  display: block;
}
.el-row {
  margin-bottom: 10px;
}
.hot_item .el-badge__content {
  margin-top: 10px;
  margin-right: -10px;
  color: #ffffff;
  background: red;
}

.video-box .el-dialog__body {
  padding: 20px;
  display: flex;
}

.video-box .el-dialog__body .list-left,
.video-box .el-dialog__body .list-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.video-box .el-dialog__body .list-left .video-list-box{
  display: flex;
}

.video-box .el-dialog__body .list-left .video-list-box .video-list{
  width: 50%;
}

.video-box .el-dialog__body .list-left .faces-list {
  height: 100px;
  display: flex;
  margin-top: 40px;
  gap: 0 10px;
}

.video-box .el-dialog__body .list-right .right-list {
  display: flex;
  align-items: center;
  gap: 0 20px;
  min-height: 40px;
}

.video-box .el-dialog__body .list-right .right-list .state-list {
  display: flex;
  align-items: center;
  gap: 0 10px;
  height: 40px;
}

.video-box .el-dialog__body .list-right .btns {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 60px;
}
</style>
